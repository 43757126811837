import { DateTimeFormat, DateTimeFormats, NumberFormats } from 'vue-i18n';

export enum Locales {
	EN = 'en',
	DE = 'de',
	CZ = 'cs',
	HU = 'hu'
}

const currency_format: NumberFormats = {
	eur: {
		currency: {
			style: 'currency',
			currency: 'EUR'
		},
		currencyShort: {
			style: 'currency',
			currency: 'EUR',
			maximumFractionDigits: 0,
			minimumFractionDigits: 0
		}
	},
	czk: {
		currency: {
			style: 'currency',
			currency: 'CZK'
		},
		currencyShort: {
			style: 'currency',
			currency: 'CZK',
			maximumFractionDigits: 0,
			minimumFractionDigits: 0
		}
	},
	huf: {
		currency: {
			style: 'currency',
			currency: 'HUF'
		},
		currencyShort: {
			style: 'currency',
			currency: 'HUF',
			maximumFractionDigits: 0,
			minimumFractionDigits: 0
		}
	}
};

export const format_currency = {
	// .at -> Always Euros
	'de-AT': currency_format.eur,
	'en-AT': currency_format.eur,
	'cs-AT': currency_format.eur,
	'hu-AT': currency_format.eur,
	// .de -> Always Euros
	'de-DE': currency_format.eur,
	'en-DE': currency_format.eur,
	'cs-DE': currency_format.eur,
	'hu-DE': currency_format.eur,
	// .cz  -> Always CZK
	'de-CZ': currency_format.czk,
	'en-CZ': currency_format.czk,
	'cs-CZ': currency_format.czk,
	'hu-CZ': currency_format.czk,
	// .hu  -> Always HUF
	'de-HU': currency_format.huf,
	'en-HU': currency_format.huf,
	'cs-HU': currency_format.huf,
	'hu-HU': currency_format.huf
};

const date_format: DateTimeFormat = {
	human: {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit'
	},
	humanDetail: {
		weekday: 'short',
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: 'numeric',
		minute: 'numeric'
	},
	month: {
		year: 'numeric',
		month: 'short'
	}
};

export const format_date_time: DateTimeFormats = {
	// .at -> Always Euros
	'de-AT': date_format,
	'en-AT': date_format,
	'cs-AT': date_format,
	'hu-AT': date_format,
	// .de -> Always Euros
	'de-DE': date_format,
	'en-DE': date_format,
	'cs-DE': date_format,
	'hu-DE': date_format,
	// .cz  -> Always CZK
	'de-CZ': date_format,
	'en-CZ': date_format,
	'cs-CZ': date_format,
	'hu-CZ': date_format,
	// .hu  -> Always HUF
	'de-HU': date_format,
	'en-HU': date_format,
	'cs-HU': date_format,
	'hu-HU': date_format
};
