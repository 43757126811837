/* eslint-disable prefer-regex-literals */
import Vue from 'vue';
import axios, { AxiosError, AxiosResponse } from 'axios';
import dayjs from 'dayjs';

import { LocaleMessageObject } from 'vue-i18n';
import { get as getBu } from '@/plugins/buinfo';

require('dayjs/locale/en');
require('dayjs/locale/de');
require('dayjs/locale/cs');
require('dayjs/locale/hu');

interface StringIndex {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
}

const localeHandler = Vue.extend({
	computed: {
		currentLocale(): string {
			return this.$i18n.locale;
		},

		localeToBCP47(): string {
			return this.$i18n.locale.split('-')[0];
		},

		getStartingLocale(): string {
			let lang;

			const default_lang = {
				at: 'de',
				de: 'de',
				cz: 'cs',
				hu: 'hu'
			} as StringIndex;

			const stored_lang = sessionStorage.getItem('lang');

			if (stored_lang && stored_lang.length === 5) {
				lang = stored_lang;
			}

			if (!lang) {
				// set the default lang according on domain
				const bu = this.$buinfo.get();
				lang = [default_lang[bu], bu.toUpperCase()].join('-');
			}

			sessionStorage.setItem('lang', lang);

			return lang;
		}
	},

	async created() {
		const lang = this.getStartingLocale;
		await this.loadLocaleMessagesAsync(lang);
	},

	methods: {
		async fetchTranslation(bu: string, lang: string): Promise<LocaleMessageObject | false> {
			const url = `https://api.123-transporter.${getBu()}/v2/basedata/${bu}/translation?lang=${lang}`;

			return await axios
				.get(url)
				.then((res: AxiosResponse) => {
					return res.data;
				})
				.catch((_err: AxiosError) => {
					return false;
				});
		},

		setI18nLanguage(lang: string): string {
			this.$i18n.locale = lang;
			axios.defaults.headers.common['Accept-Language'] = lang;

			let el;
			if (document) {
				el = document.querySelector<HTMLElement>('html'); // typecasting to prevent stupid error 'Object is possibly null'
				if (!el) {
					throw new ReferenceError('Document selector not found.');
				}
				document.title = this.$t('document_title.fss') as string;
				el.setAttribute('lang', lang);
			}

			dayjs.locale(this.localeToBCP47);

			return lang;
		},

		isLocaleAlreadyLoaded(locale: string): boolean {
			const loaded_trans_keys = Object.keys(this.$i18n.messages);
			return loaded_trans_keys.includes(locale) && typeof this.$i18n.messages[locale] !== 'boolean';
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async loadLocaleMessagesAsync(specific_loc?: string): Promise<void> {
			const locale = specific_loc ?? this.currentLocale;

			if (this.isLocaleAlreadyLoaded(locale)) {
				this.setI18nLanguage(locale);
				return Promise.resolve();
			}

			await this.loadFallbackLocales(locale);

			// load actual locale
			const [lang, bu] = locale.split('-');
			const res = await this.fetchTranslation(bu, lang);
			if (res) {
				this.$i18n.setLocaleMessage(locale, res);
			}
			this.setI18nLanguage(locale);

			return Promise.resolve();
		},

		async loadFallbackLocales(locale: string): Promise<void> {
			// type FallbackLocale = string | string[] | false | { [locale: string]: string[] }

			// no fallback defined
			if (this.$i18n.fallbackLocale === undefined || this.$i18n.fallbackLocale === false) {
				return Promise.resolve();
			}

			// fallback is just 1 locale
			if (typeof this.$i18n.fallbackLocale === 'string') {
				if (!this.isLocaleAlreadyLoaded(this.$i18n.fallbackLocale)) {
					const [lang, bu] = this.$i18n.fallbackLocale.split('-');
					const res = await this.fetchTranslation(bu, lang);

					if (res) {
						this.$i18n.setLocaleMessage(this.$i18n.fallbackLocale, res);
					}
				}
				return Promise.resolve();
			}

			// fallback is an array of locales
			if (Array.isArray(this.$i18n.fallbackLocale)) {
				for (const fb_locale of this.$i18n.fallbackLocale) {
					if (!this.isLocaleAlreadyLoaded(fb_locale)) {
						const [lang, bu] = fb_locale.split('-');
						const res = await this.fetchTranslation(bu, lang);

						if (res) {
							this.$i18n.setLocaleMessage(fb_locale, res);
						}
					}
				}
				return Promise.resolve();
			}

			// fallback is an object
			// if 'default' fallback is defined
			if (this.$i18n.fallbackLocale['default'] !== undefined) {
				for (const fb_locale of this.$i18n.fallbackLocale['default']) {
					if (!this.isLocaleAlreadyLoaded(fb_locale)) {
						const [lang, bu] = fb_locale.split('-');
						const res = await this.fetchTranslation(bu, lang);

						if (res) {
							this.$i18n.setLocaleMessage(fb_locale, res);
						}
					}
				}
			}

			// if fallback is defined for the requested locale
			if (this.$i18n.fallbackLocale[locale] !== undefined) {
				for (const fb_locale of this.$i18n.fallbackLocale[locale]) {
					if (!this.isLocaleAlreadyLoaded(fb_locale)) {
						const [lang, bu] = fb_locale.split('-');
						const res = await this.fetchTranslation(bu, lang);

						if (res) {
							this.$i18n.setLocaleMessage(fb_locale, res);
						}
					}
				}
			}

			return Promise.resolve();
		}
	}
});

export default localeHandler;
