import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { format_currency, format_date_time } from './locales';

Vue.use(VueI18n);

export function getStartingLocale(): string {
	if (!sessionStorage.getItem('lang')) {
		const starting_locale = process.env.VUE_APP_I18N_LOCALE || 'en-AT';
		sessionStorage.setItem('lang', starting_locale);
		return starting_locale;
	}
	return sessionStorage.getItem('lang') as string;
}

const startingLocale = getStartingLocale();

const i18n = new VueI18n({
	locale: startingLocale,
	formatFallbackMessages: true,
	fallbackLocale: {
		'de-DE': ['de-AT'],
		'de-CZ': ['de-AT'],
		'de-HU': ['de-AT'],
		'en-DE': ['en-AT'],
		'en-CZ': ['en-AT'],
		'en-HU': ['en-AT'],
		'cs-DE': ['cs-CZ'],
		'cs-AT': ['cs-CZ'],
		'cs-HU': ['cs-CZ'],
		'hu-DE': ['hu-HU'],
		'hu-AT': ['hu-HU'],
		'hu-CZ': ['hu-HU'],

		default: ['en-AT']
	},
	numberFormats: format_currency,
	dateTimeFormats: format_date_time
});

export default i18n;
