import { VueConstructor } from 'vue';

declare module 'vue/types/vue' {
	interface Vue {
		$buinfo: {
			get: (upper?: boolean) => string;
			contact: () => {
				tel: string;
				tel_format: string;
				email: string;
			};
			getUrl: (bu: string) => string;
		};
	}
}

interface ContactInfo {
	tel: string;
	tel_format: string;
	email: string;
}

const data = {
	at: {
		tel: '0043720500246123',
		tel_format: '+43 720 500 246 123',
		email: 'support@123-transporter.at'
	},
	de: {
		tel: '004999199992921',
		tel_format: '+49 991 999 929 21',
		email: 'support@123-transporter.de'
	},
	cz: {
		tel: '00420800999123',
		tel_format: '+420 80 09 99 123',
		email: 'support@123-transporter.cz'
	},
	hu: {
		tel: 'xxxxxx hu xxxxxxx',
		tel_format: 'xxxxxx hu xxxxxxx',
		email: 'support@123-transporter.hu'
	}
} as {
	[index: string]: ContactInfo;
};

export const get = function (upper?: boolean): string {
	upper = !!upper;

	const domain = window.location.host.split(':')[0];
	const tld = domain.split('.').pop() || 'at'; //default to AT

	return upper ? tld.toUpperCase() : tld.toLowerCase();
};

const contact = function (): ContactInfo {
	const bu = get();
	return {
		tel: data[bu].tel,
		tel_format: data[bu].tel_format,
		email: data[bu].email
	};
};

const getUrl = function (bu: string): string {
	bu = bu.toUpperCase();
	if (get(true) == bu) {
		return window.location.protocol + '//' + window.location.host;
	}

	const parts = window.location.host.split(':');
	const domain = parts.shift() || '';
	const domain_parts = domain.split('.');

	domain_parts.pop();
	domain_parts.push(bu.toLowerCase());
	parts.unshift(domain_parts.join('.'));
	return window.location.protocol + '//' + parts.join(':');
};

export default {
	install: (Vue: VueConstructor): void => {
		Vue.prototype.$buinfo = { get, contact, getUrl };
	}
};
