import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"max-width":"700","persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,{staticClass:"pa-1"},[_c(VCardTitle,{class:`${_vm.color}--text`},[_c('h3',{staticClass:"heading-bold primary--text mt-1",domProps:{"innerHTML":_vm._s(_vm.title)}})]),_c(VCardText,{staticClass:"mt-3"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.message)}})]),_c(VCardText,[_c(VForm,{ref:"form"},[(_vm.isTextArea)?_c(VTextarea,{attrs:{"label":_vm.label,"rules":_vm.required ? [_vm.rules.required] : []},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}):(_vm.isFilePicker)?_c(VFileInput,{attrs:{"accept":".pdf","color":"primary","placeholder":_vm.label,"rules":_vm.required ? [_vm.rules.required] : [],"show-size":1000},scopedSlots:_vm._u([{key:"selection",fn:function({ index, text }){return [(index < 2)?_c(VChip,{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):_vm._e()]}}]),model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}):(_vm.isCheckbox)?_c(VCheckbox,{attrs:{"label":_vm.label},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}):_c(VTextField,{attrs:{"label":_vm.label,"type":_vm.type,"rules":_vm.required ? [_vm.rules.required] : []},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)],1),_c(VCardActions,[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VBtn,{attrs:{"elevation":"0","block":""},on:{"click":_vm.clickCancel}},[_vm._v(" "+_vm._s(_vm.$t('actions.cancel'))+" ")])],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VBtn,{attrs:{"elevation":"0","block":"","color":"primary"},on:{"click":_vm.clickOk}},[_vm._v(" "+_vm._s(_vm.btnOkText)+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }