import {
	FetchTruckList,
	GetOneTruck,
	UpdateOneTruck,
	CreateOneTruck,
	UploadTruckFile,
	RequestTruckCheck,
	SetImmobilizer,
	SetCentralLock,
	GetTruckSlots,
	GetTruckMileage,
	getTruckTelemetryStatus,
	UpdateTruckQRCode
} from './endpoints/truck';
import { FetchUserList, GetOneUser, UpdateOneUser, CreateOneUser, SetUserBatches, FetchUserGroupList, AddGroup, RemoveGroup, ResetPassword, UploadAvatar } from './endpoints/user';
import { FetchNotificationSettings, UpdateNotificationSettings, MultiUpdateNotificationSettings } from './endpoints/user';
import { AddIdDoc } from './endpoints/user';
import { FetchModelList, GetOneModel, CreateOneModel, UploadModelFile, UpdateOneModel } from './endpoints/model';
import { DownloadAccountingFile, FetchAccountingList } from './endpoints/accounting';
import { DownloadStatsFile, FetchStats } from './endpoints/stats';
import { DownloadTodoList, FailTodo, FetchTodoList, ResetTodo, SetTodoDone } from './endpoints/todo';
import {
	FetchWorkorderList,
	GetOneWorkorder,
	UpdateWorkorder,
	CreateWorkorder,
	UploadWorkorderFile,
	startWorkorder,
	getWorkorderConditions,
	finishWorkorder,
	MergeWorkorder
} from './endpoints/workorder';
import { FetchThreadList, CreateThread, FetchThread, FetchEventList, CreateEvent, CloseThread } from './endpoints/thread';
import { ChangePassword, Login, Logout } from './endpoints/auth';
import { FetchPartnerList } from './endpoints/partner';
import { getTruckActive, getTruckActiveCount, getTruckLoad, getWidgetInfo, getWidgetLast30 } from './endpoints/widget';
import axios from 'axios';

import { get as getBu } from '@/plugins/buinfo';

let api_base_url = process.env.VUE_APP_APIv2_BASE_URL as string;
api_base_url = api_base_url.replace('.at', `.${getBu()}`);

axios.defaults.withCredentials = true;
axios.defaults.baseURL = api_base_url;

export * from './helper';

export default {
	auth: {
		login: Login,
		logout: Logout,
		changePassword: ChangePassword
	},
	partner: {
		list: FetchPartnerList
	},
	truck: {
		list: FetchTruckList,
		get: GetOneTruck,
		update: UpdateOneTruck,
		updateQR: UpdateTruckQRCode,
		create: CreateOneTruck,
		uploadFile: UploadTruckFile,
		requestCheck: RequestTruckCheck,
		telemetry: {
			centralLock: SetCentralLock,
			immobilzer: SetImmobilizer,
			status: getTruckTelemetryStatus
		},
		slots: GetTruckSlots,
		mileage: GetTruckMileage
	},
	model: {
		list: FetchModelList,
		get: GetOneModel,
		create: CreateOneModel,
		update: UpdateOneModel,
		uploadFile: UploadModelFile
	},
	user: {
		list: FetchUserList,
		get: GetOneUser,
		update: UpdateOneUser,
		create: CreateOneUser,
		setBatches: SetUserBatches,
		addGroup: AddGroup,
		removeGroup: RemoveGroup,
		resetPasswort: ResetPassword,
		addIdDoc: AddIdDoc,
		uploadAvatar: UploadAvatar,
		group: {
			list: FetchUserGroupList
		},
		notifications: {
			settings: {
				list: FetchNotificationSettings,
				update: UpdateNotificationSettings,
				multiupdate: MultiUpdateNotificationSettings
			}
		}
	},
	accounting: {
		list: FetchAccountingList,
		download: DownloadAccountingFile
	},
	stats: {
		get: FetchStats,
		download: DownloadStatsFile
	},
	todo: {
		list: FetchTodoList,
		setDone: SetTodoDone,
		reset: ResetTodo,
		fail: FailTodo,
		download: DownloadTodoList
	},
	workorder: {
		list: FetchWorkorderList,
		get: GetOneWorkorder,
		update: UpdateWorkorder,
		create: CreateWorkorder,
		uploadFile: UploadWorkorderFile,
		start: startWorkorder,
		conditions: getWorkorderConditions,
		finish: finishWorkorder,
		merge: MergeWorkorder
	},
	thread: {
		list: FetchThreadList,
		create: CreateThread,
		get: FetchThread,
		createEvent: CreateEvent,
		getEvents: FetchEventList,
		close: CloseThread
	},
	widget: {
		info: getWidgetInfo,
		last30: getWidgetLast30,
		truck: {
			active: getTruckActive,
			activeCount: getTruckActiveCount,
			load: getTruckLoad
		}
	}
};
