import axios, { AxiosError, AxiosResponse } from 'axios';
import Truck, { TruckSlotInfo, TruckTelemetryStatus } from '@/models/truck';

import { ListResponse, ListRequestOrder, ListRequestFilter, buildListQuery, stringifyExpands, ErrorResponse, getAuthHeader } from '../helper';
import Downtime from '@/models/downtime';

export const FetchTruckList = async (
	page: number,
	page_size: number,
	filter?: ListRequestFilter,
	order?: ListRequestOrder,
	expands?: string[]
): Promise<ListResponse<Truck> | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/truck` + buildListQuery(page, page_size, filter, order, expands);

	return await axios
		.get(url, auth)
		.then((res: AxiosResponse) => {
			return new ListResponse<Truck>(Truck, res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const GetOneTruck = async (id: string, expands?: string[]): Promise<Truck | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/truck/${id}` + stringifyExpands(expands);

	return await axios
		.get(url, auth)
		.then((res: AxiosResponse) => {
			return new Truck(res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const UpdateOneTruck = async (id: string, truck: Truck, expands?: string[]): Promise<Truck | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/truck/${id}` + stringifyExpands(expands);

	return await axios
		.put(url, truck.toDTO(), auth)
		.then((res: AxiosResponse) => {
			return new Truck(res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const UpdateTruckQRCode = async (id: string, qrLink: string): Promise<Truck | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/truck/${id}/qr-code?link=${encodeURIComponent(qrLink)}`;

	return await axios
		.patch(url, null, auth)
		.then((res: AxiosResponse) => {
			return new Truck(res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const CreateOneTruck = async (truck: Truck, expands?: string[]): Promise<Truck | ErrorResponse> => {
	const auth = getAuthHeader();
	const url = `/truck` + stringifyExpands(expands);

	return await axios
		.post(url, truck.toDTO(), auth)
		.then((res: AxiosResponse) => {
			return new Truck(res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const UploadTruckFile = async (id: string, type: string, file: File, expands?: string[]): Promise<Truck | ErrorResponse> => {
	const config = getAuthHeader();
	const url = `/truck/${id}/file?type=${type}` + stringifyExpands(expands, false);

	const formData = new FormData();
	formData.append('file', file);
	config.headers['Content-Type'] = 'multipart/form-data';

	return await axios
		.post(url, formData, config)
		.then((res: AxiosResponse) => {
			return new Truck(res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const RequestTruckCheck = async (id: string, qr: string, tablet: string, expands?: string[]): Promise<Truck | ErrorResponse> => {
	const config = getAuthHeader();
	const url = `/truck/${id}/request-check` + stringifyExpands(expands);

	return await axios
		.patch(url, { qr, tablet }, config)
		.then((res: AxiosResponse) => {
			return new Truck(res.data);
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const SetCentralLock = async (id: string, state: string): Promise<boolean | ErrorResponse> => {
	const config = getAuthHeader();
	const url = `/truck/${id}/telemetry/central-lock?state=${state}`;

	return await axios
		.patch(url, undefined, config)
		.then(() => {
			return true;
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};
export const SetImmobilizer = async (id: string, state: string): Promise<boolean | ErrorResponse> => {
	const config = getAuthHeader();
	const url = `/truck/${id}/telemetry/immobilizer?state=${state}`;

	return await axios
		.patch(url, undefined, config)
		.then(() => {
			return true;
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const GetTruckSlots = async (id: string, start: string, end: string, site?: string, expands?: string[]): Promise<TruckSlotInfo[] | ErrorResponse> => {
	const config = getAuthHeader();
	const url = `/truck/${id}/slots?time_start=${start}&time_end=${end}` + (site ? `&site_id=${site}` : '') + stringifyExpands(expands, false);

	return await axios
		.get(url, config)
		.then((res: AxiosResponse) => {
			return res.data.map((s: { time_start: string; time_end: string; type: string; downtime?: Partial<Downtime> }) => {
				const slot = {
					time_start: new Date(s.time_start),
					time_end: new Date(s.time_end),
					type: s.type
				} as TruckSlotInfo;

				if (s.downtime) {
					slot.downtime = new Downtime(s.downtime);
				}

				return slot;
			});
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const GetTruckMileage = async (id: string): Promise<{ day: string; mileage: number }[] | ErrorResponse> => {
	const config = getAuthHeader();
	const url = `/truck/${id}/mileage`;

	return await axios
		.get(url, config)
		.then((res: AxiosResponse) => {
			return res.data;
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};

export const getTruckTelemetryStatus = async (id: string): Promise<TruckTelemetryStatus | ErrorResponse> => {
	const config = getAuthHeader();
	const url = `/truck/${id}/telemetry-status`;

	return await axios
		.get(url, config)
		.then((res: AxiosResponse) => {
			return res.data as TruckTelemetryStatus;
		})
		.catch((err: AxiosError) => {
			return new ErrorResponse(err.response?.data as ErrorResponse);
		});
};
