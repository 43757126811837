import _Vue from 'vue';
import VueI18n from 'vue-i18n';
import Vuetify from 'vuetify';

import ConfirmDialog from './components/ConfirmDialog.vue';
import AlertDialog from './components/AlertDialog.vue';
import PromptDialog from './components/PromptDialog.vue';

declare module 'vue/types/vue' {
	interface Vue {
		$dialogs: {
			confirm: (message: string, title?: string, opt?: { color?: string; emphasizeCancel?: boolean }) => Promise<boolean>;
			alert: (message: string, title?: string, opt?: { color?: string }) => Promise<boolean>;
			prompt: (
				message: string,
				title?: string,
				opt?: {
					type?: string;
					defaultValue?: string;
					required?: boolean;
					label?: string;
					color?: string;
					okText?: string;
				}
			) => Promise<string | boolean | File | File[] | undefined>;
		};
	}
}

export default {
	install: (
		Vue: typeof _Vue,
		{
			vuetify,
			i18n
		}: {
			vuetify: Vuetify;
			i18n: VueI18n;
		}
	): void => {
		if (!vuetify) {
			throw new Error('Please provide vuetify. (dialogs plugin)');
		}
		if (!i18n) {
			throw new Error('Please provide i18n. (dialogs plugin)');
		}

		Vue.prototype.$dialogs = {
			confirm: async function (message: string, title?: string, opt?: { color?: string; emphasizeCancel?: boolean }): Promise<boolean> {
				return new Promise((res) => {
					const instance = new ConfirmDialog({
						vuetify,
						i18n,
						propsData: {
							title,
							message,
							color: opt?.color,
							emphasizeCancel: opt?.emphasizeCancel
						}
					});

					instance.$mount();
					instance.$on('click', res);
				}) as Promise<boolean>;
			},
			alert: async function (message: string, title?: string, opt?: { color?: string }): Promise<boolean> {
				return new Promise((res) => {
					const instance = new AlertDialog({
						vuetify,
						i18n,
						propsData: {
							title,
							message,
							color: opt?.color
						}
					});

					instance.$mount();
					instance.$on('click', res);
				}) as Promise<boolean>;
			},
			prompt: async function (
				message: string,
				title?: string,
				opt?: { type?: string; defaultValue?: string; required?: boolean; label?: string; color?: string; okText?: string }
			): Promise<string | boolean | File | File[]> {
				return new Promise((res) => {
					const instance = new PromptDialog({
						vuetify,
						i18n,
						propsData: {
							title,
							message,
							type: opt?.type,
							default: opt?.defaultValue,
							required: opt?.required,
							label: opt?.label,
							color: opt?.color,
							okText: opt?.okText
						}
					});

					instance.$mount();
					instance.$on('click', res);
				}) as Promise<boolean>;
			}
		};
	}
};
