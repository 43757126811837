import Vue from 'vue';
import App from './App.vue';
import router from './router';
import i18n from '@/i18n';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import VueTelInputVuetify from 'vue-tel-input-vuetify';
import snackbarPlugin from '@/plugins/snackbar';
import buifnoPlugin from '@/plugins/buinfo';
import dialogsPlugin from '@/plugins/dialogs';
import AvatarCropper from 'vue-avatar-cropper';
import * as VueGoogleMaps from 'vue2-google-maps';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import '@/assets/styles/global.scss';

Vue.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GOOGLE_API_KEY
		//libraries: 'places', // This is required if you use the Autocomplete plugin
		// OR: libraries: 'places,drawing'
		// OR: libraries: 'places,drawing,visualization'
		// (as you require)

		//// If you want to set the version, you can do so:
		// v: '3.26',
	},

	//// If you intend to programmatically custom event listener code
	//// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
	//// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
	//// you might need to turn this on.
	// autobindAllEvents: false,

	//// If you want to manually install components, e.g.
	//// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
	//// Vue.component('GmapMarker', GmapMarker)
	//// then set installComponents to 'false'.
	//// If you want to automatically install all the components this property must be set to 'true':
	installComponents: true
});
Vue.component('GmapCluster', GmapCluster);

Vue.use(buifnoPlugin);
Vue.use(snackbarPlugin, { store });
Vue.use(dialogsPlugin, { vuetify, i18n });
Vue.use(AvatarCropper);
Vue.use(VueTelInputVuetify, {
	vuetify
});

Vue.config.productionTip = false;

if (process.env.VUE_APP_ENABLE_SENTRY == 'true') {
	Sentry.init({
		Vue,
		dsn: 'https://49e268d42c474c43a74e60293623410a@o1319652.ingest.sentry.io/6610626',
		integrations: [
			new BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
				tracingOrigins: [/^\//]
			})
		],
		ignoreErrors: ['ChunkLoadError', 'NavigationDuplicated', 'navigation guard'],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0
	});
}

new Vue({
	router,
	store,
	i18n,
	vuetify,
	render: (h) => h(App)
}).$mount('#app');
